<template>
  <div class="flex flex-col items-center mt-10">
    <!-- Left Side: Billing Tabs and Order Summary -->
    <!-- Title Section -->
    <input data-test="summary_plan_price" type="hidden" v-model="monthlyTotal" />
    <div class="text-center mb-16">
      <h1 class="text-3xl">Almost there!</h1>
      <p class="text-3xl">Just fill in the details below so we can serve up your plan soonest.</p>
    </div>
    <div class="flex flex-col md:flex-row md:justify-center w-full mt-10">
      <div class="w-full md:w-1/2 max-w-xl rounded-lg mb-4 md:mb-0">
        <!-- Tabs for Billing -->
        <div v-if="hasAnnual" class="flex justify-between mb-4 border border-red-600">
          <button
            class="w-1/2 py-2 font-semibold text-center"
            :class="billing === 'monthly' ? 'bg-red-600 text-white' : 'bg-white text-black'"
            :disabled="billing === 'monthly'"
            @click="toggleBilling('monthly')"
          >
            Monthly Billing
          </button>
          <button
            class="w-1/2 py-2 font-semibold text-center border-l border-red-600"
            :class="billing === 'yearly' ? 'bg-red-600 text-white' : 'bg-white text-black'"
            :disabled="billing === 'yearly'"
            @click="toggleBilling('yearly')"
          >
            {{
              billing === 'yearly'
                ? 'Annual Billing'
                : 'Save extra 2% off your bill when you choose Annual Billing!'
            }}
          </button>
        </div>

        <!-- Order Summary -->
        <div class="py-4">
          <h2 class="text-2xl text-red-600 mb-4 text-left">Order Summary</h2>
          <p class="text-sm text-gray-600 mb-6 leading-6 text-left">
            ViewQwest will only process the first payment upon successful service activation of your
            line. Upon service activation, we will bill within 3 working days for the relevant
            subscription and one-time fees (if applicable).
          </p>

          <!-- Monthly Payment Info -->
          <div class="border-b border-red-600 py-4 mb-6 text-left">
            <div>
              <div class="flex justify-between items-center">
                <span class="text-lg font-semibold text-gray-800">
                  Here’s the {{ billing === 'monthly' ? 'Monthly' : 'Annual' }} Payment for your
                  {{ planSpeed }}
                </span>

                <span class="text-lg font-bold text-gray-800">{{
                  billing === 'monthly' ? planPrice : annualPriceFormatted
                }}</span>
              </div>
            </div>

            <!-- Add the description below the payment title -->
            <div class="flex justify-between items-start mt-1" v-if="paymentDescription">
              <span class="text-sm text-gray-600 italic">
                {{ paymentDescription }}
              </span>
            </div>

            <div class="flex justify-between items-start mt-2" v-if="promoDetails?.description">
              <span class="text font-semibold text-gray-800 text-left">
                <!-- {{ promoDetailsLabel?.label }} Bring back that if needed => Promo code applied -->
                {{ promoDetails.description }}
              </span>
              <span class="text font-bold text-gray-800">{{ promoDetails.label }}</span>
            </div>

            
            <!-- Plan contract -->
            <div class="text-left flex justify-between items-center my-2">
              <p class="text-md text-gray-800 font-semibold">Plan Contract</p>
              <p class="text-md text-gray-800">24 Months</p>
            </div>

          </div>

          <!-- Router Info -->
          <div class="border-b border-red-600 py-4 text-left">
            <div class="flex justify-between">
              <h4 class="text-lg font-semibold text-gray-800">Your Router:</h4>
              <h4 class="text-lg font-semibold text-gray-800">
                {{ routerName || "I don't need a router." }}
              </h4>
            </div>
            <ul class="list-none space-y-2 mt-2">
              <li
                v-for="service in addedServices"
                :key="service.label"
                class="flex justify-between"
              >
                <span class="text-gray-700">{{ service.label }}</span>
                <span class="text-gray-700">{{ service.amount || 'Free' }}</span>
              </li>
            </ul>
          </div>

          <!-- Monthly Total -->
          <div class="border-b border-red-600 py-4 text-left">
            <div class="flex justify-between items-center">
              <span class="text-lg font-semibold text-gray-800">Monthly Total</span>
              <span class="text-lg font-bold text-gray-800">{{ monthlyTotal }}</span>
            </div>
            <ul class="list-none space-y-2 mt-2">
              <li v-for="item in freebiesDetails" :key="item.label" class="flex justify-between">
                <span class="text-gray-700">{{ item.label }}</span>
              </li>
            </ul>
          </div>

          <!-- One-Time Charges -->
          <div class="border-b border-red-600 py-4 text-left">
            <ul class="list-none space-y-2">
              <li v-for="(charge, index) in planCharges" :key="index" class="flex justify-between">
                <span class="text-gray-700 font-semibold">{{ charge.label }}</span>
                <span class="text-gray-700 font-semibold">{{ charge.amount || 'Free' }}</span>
              </li>
            </ul>
          </div>

          <div class="flex justify-between mt-4 text-lg">
            <span class="font-semibold">Total One-Time Charges</span>
            <span class="font-semibold">{{ oneTimeTotal }}</span>
          </div>

          <!-- Disclaimer -->
          <div class="text-xs text-gray-500 mt-4 text-left">
            <p class="italic">
              All prices reflected are inclusive of 9% GST. All broadband plans & promotions are
              subject to terms & conditions.
            </p>
          </div>
        </div>
        <!-- Add Submit Button Below Order Summary -->
        <div class="flex justify-end mt-6" v-if="!showPayment">
          <button
            class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded inline-flex items-center"
            @click="noPaymentMethod"
          >
            <span>Submit</span>
            <svg
              class="ml-2 h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 12h14m-7-7l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        class="ml-8 flex flex-col md:flex-row items-start justify-center md:space-x-8 md:border-l md:border-gray-300"
      ></div>
      <!-- Right Side: Payment Section -->
      <div v-if="showPayment" class="w-full md:w-1/2 max-w-md px-4 md:px-8 rounded-lg">
        <!-- Title and Card Section -->
        <div class="mb-8" v-if="!isZuoraShow">
          <h3 class="text-xl text-gray-800 font-semibold text-left">Select Payment Method</h3>
        </div>
        <div v-else>
          <div id="zuora_payment" class="text-left"></div>
        </div>

        <div v-if="cards.length > 0 && !isZuoraShow" class="card-selection space-y-6 mt-6">
          <div
            v-for="card in cards"
            :key="card.paymentId"
            class="card-item flex items-center justify-between p-5 border border-gray-200 rounded-lg shadow-sm hover:shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-100"
          >
            <!-- Left side: Radio button and Card image -->
            <div class="flex items-center space-x-4">
              <!-- Radio button for card selection -->
              <input
                type="radio"
                :value="card.paymentId"
                v-model="selectedCard"
                name="card"
                class="h-5 w-5 text-blue-600 focus:ring-blue-500 cursor-pointer transition-transform transform hover:scale-105"
              />
              <!-- Card image -->
              <img
                :src="getCardImage(card.creditCardType)"
                alt="Card Image"
                class="w-12 h-auto object-contain"
              />
            </div>

            <!-- Right side: Card details and badge -->
            <div class="flex items-center space-x-6">
              <div class="flex flex-col items-end">
                <!-- Card Number -->
                <span class="text-lg font-semibold text-gray-800 tracking-wider">
                  {{ card.cardNumber }}
                </span>
                <!-- Default badge and Expiration date -->
                <div class="flex items-center space-x-3 mt-2">
                  <!-- Default badge -->
                  <div
                    v-if="card.isDefault"
                    class="bg-green-100 text-green-700 text-xs font-semibold py-1 px-3 rounded-full"
                  >
                    Default
                  </div>
                  <!-- Expiration date -->
                  <span class="text-sm text-gray-500">
                    Exp: {{ card.expMonth }} / {{ card.expYear }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Add New Card Link -->
        <div
          class="flex items-center justify-between space-x-2 mt-4"
          :style="devButtonStyle"
          v-if="!isZuoraShow"
        >
          <CreditCardIcon class="h-6 w-6 text-gray-600" />
          <button class="text-blue-600 font-semibold hover:underline text-left" @click="addNewCard">
            + Add New Card
          </button>
        </div>

        <!-- Placeholder for Payment -->

        <div class="mt-6 text-left">
          <!-- Container for Developer and Submit Button -->
          <div class="flex justify-between items-center">
            <!-- Developer Button -->
            <div v-if="isDevEnv">
              <button
                @click="callPaymentMethodId"
                data-test="devsButton"
                class="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                :style="devButtonStyle"
                :disabled="loadingPayment"
              >
                FOR DEVS ONLY
              </button>
            </div>
            <!-- Submit Button -->
            <div v-if="cards.length > 0 && showPayment && !isZuoraShow" class="ml-auto">
              <button
                class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-lg inline-flex items-center shadow-md transition-transform duration-300 ease-in-out hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                @click="submitCardSelection"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Loader to show while processing payment method -->
      <div v-if="loadingPayment" class="loader-overlay mt-4">
        <Loader />
      </div>
    </div>
    <!-- Support Contact Section -->
    <div class="text-center mt-8">
      <p class="text-sm text-gray-500">
        In case your signup attempt is not successful, please reach out to our friendly staff at
        <a
          href="mailto:retail.sg@viewqwest.com"
          class="text-blue-600 font-semibold hover:underline"
        >
          retail.sg@viewqwest.com </a
        >.
        <br />
        They will get in touch to assist you as soon as possible.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { usePlanStore } from '@/stores/planStore'
import { defineComponent, ref, onMounted, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import Loader from '@/components/Loader.vue'
import { CreditCardIcon } from '@heroicons/vue/24/outline'
import Swal from 'sweetalert2'
import PaymentComponent from '@/components/PaymentComponent.vue'

export default defineComponent({
  name: 'SummaryComponent',
  components: {
    Loader,
    CreditCardIcon,
    PaymentComponent
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const billing = ref('monthly')
    const planStore = usePlanStore()
    const router = useRouter() // Initialize Vue Router
    const loadingPayment = ref(false) // Track payment method loading
    const cards = ref([]) // Store cards fetched from the backend
    const selectedCard = ref(null) // Track the selected card
    const paymentStatus = ref(false)
    const isZuoraShow = ref(false)
    const hasAnnual = ref(false)
    const zId = ref(null)
    // Local variables to store the extracted data
    const planSpeed = ref('')
    const planPrice = ref('')
    const annualPrice = ref('')
    const routerName = ref('')
    const monthlyTotal = ref('')
    const oneTimeTotal = ref('')
    const addedServices = ref([])
    const freebiesDetails = ref([])
    const promoDetails = ref(null)
    const promoDetailsLabel = ref(null)
    const annualPlan = ref(null)
    const paymentDescription = ref('')
    const showPayment = ref('')
    const planCharges = ref([])

    // Check if it's a development environment
    // import.meta.env.VITE_production === 'no'
    const isDevEnv = ref(false)

    const noPaymentMethod = async () => {
      if (import.meta.env.VITE_cash_payment_id) {
        loadingPayment.value = true
        await planStore.payment_method_id(import.meta.env.VITE_cash_payment_id, 'false')
        router.push({ name: 'success-welcome-to-viewqwest' })
      }
    }

    const callPaymentMethodId = async () => {
      const paymentMethodId = import.meta.env.VITE_payment_method_id // Get payment method ID from env
      if (paymentMethodId) {
        loadingPayment.value = true // Show the loader
        try {
          await planStore.payment_method_id(paymentMethodId, 'false') // Call the action with the ID
          router.push({ name: 'success-welcome-to-viewqwest' }) // On success, redirect to the success route
        } catch (error) {
          console.error('Error processing payment method ID:', error)
        } finally {
          loadingPayment.value = false // Hide the loader
        }
      } else {
        console.error('No payment method ID provided.')
      }
    }

    // Handle the submission of billing data
    const submitBillingData = async (billingData: any) => {
      try {
        const response = await planStore.submitSignupData(billingData)
        // console.log('Billing data submitted successfully', response)
        await planStore.fetchSummary()
      } catch (error) {
        console.error('Error submitting billing data:', error)
      }
    }

    const formatPrice = (price) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(price)
    }

    const annualPriceFormatted = computed(() => {
      return formatPrice(annualPrice.value)
    })

    // Function to toggle billing type
    const toggleBilling = async (type: string) => {
      if (!annualPlan.value) {
        return
      }
      let billingData
      const selectedRouter: any = planStore.getSelectedPlan.selectedRouter
      const selectedEnhancements = planStore.getSelectedPlan.selectedEnhancements
      if (type === 'yearly') {
        // console.log('Switching to Annual Billing')
        billingData = {
          origin: window.location.href,
          plan_otc_name: selectedRouter.one_time_charge_name,
          plan_segment: selectedRouter.plan_segment,
          plan_sf: selectedRouter.router_name,
          plan_contract: selectedRouter.plan_contract,
          plan_vas: selectedEnhancements.map((vas: any) => vas?.key),
          router: selectedRouter.router_sf,
          user_device: planStore.user_device,
          plan_id: annualPlan.value[0].plan_key,
          plan_price: annualPlan.value[0].plan_price,
          plan_type: annualPlan.value[0].plan_type,
          signup_id: planStore.signupId,
          signup_id_hash: planStore.idHash
        }
        annualPrice.value = annualPlan.value[0].plan_price
        await submitBillingData(billingData)
        billing.value = 'yearly'
      } else {
        // console.log('Switching to Monthly Billing')
        billingData = {
          origin: window.location.href,
          plan_id: selectedRouter?.router_key || '',
          plan_otc_name: selectedRouter?.one_time_charge_name || '',
          plan_price: selectedRouter?.price || '',
          plan_segment: selectedRouter?.plan_segment || '',
          plan_sf: selectedRouter?.router_name || '',
          plan_contract: selectedRouter?.plan_contract || '',
          plan_type: planStore.getSelectedPlan.plan?.plan_type || '',
          plan_vas: selectedEnhancements.map((vas: any) => vas?.key),
          router: selectedRouter?.router_sf || '',
          user_device: planStore.user_device,
          signup_id: planStore.signupId,
          signup_id_hash: planStore.idHash
        }
        await submitBillingData(billingData)
        billing.value = 'monthly'
      }
    }

    watch(
      () => planStore.summaryDetails,
      async (newSummary) => {
        if (newSummary) {
          billing.value = 'monthly'
          dispaySummary()
        }
      },
      { deep: true } // Ensure deep watching for nested changes in `summaryDetails`
    )

    // Fetch summary details on component mount
    const fetchSummaryDetails = async () => {
      if (!props.loading) {
        try {
          await planStore.fetchSummary()
          dispaySummary()
        } catch (error) {
          console.error('Error fetching summary:', error)
        }
      }
    }

    const dispaySummary = () => {
      const signup = planStore?.summaryDetails?.data?.signup || null

      const ref = planStore?.summaryDetails?.data.signup.meta.referral
        ? planStore?.summaryDetails?.data.signup.meta.referral
        : false
      const payment_terms = planStore?.summaryDetails?.data.signup.meta.plan[3]

      annualPlan.value = payment_terms.children
      if (payment_terms.children.length > 0 && planStore.hasPaymentTerms === false) {
        planStore.hasPaymentTerms = true
        const noPlanBanner = payment_terms.children[0].plan_banner

        annualPlanModal(payment_terms)
      }
      if (signup) {
        // Plan details
        planSpeed.value = signup.meta.plan[0]?.children[0]?.children[0]?.label || ''
        planPrice.value = signup.meta.plan[0]?.children[0]?.amount || ''
        routerName.value = signup.meta.plan[1]?.children[0]?.children[0]?.label || ''
        paymentDescription.value = signup.meta.plan[0]?.children[5]?.amount || ''
        const promoData = signup.meta.plan[2]

        if (promoData.children.length > 0) {
          promoDetails.value = promoData.children[0].children[0]
          promoDetailsLabel.value = promoData.children[0]
        }

        // console.log('promo', promoDetails.value)
        // console.log('promoDetailsLabel', promoDetailsLabel.value)

        // Added services
        addedServices.value = signup.meta.plan[0]?.children[1].children || []

        // Monthly and one-time totals
        monthlyTotal.value = signup.meta.plan[0]?.children[4]?.amount || ''
        oneTimeTotal.value = signup.meta.plan[1]?.children[2]?.amount || ''

        if (signup.meta.plan[1]?.children[1]?.children) {
          planCharges.value = signup.meta.plan[1]?.children[1]?.children.map((child) => ({
            label: child.label,
            amount: child.amount || 'Free'
          }))
        }

        // Freebies details
        freebiesDetails.value = signup.meta.plan[0]?.children[2]?.children || []

        const isShowPayment = signup.meta.plan[4].children
        // console.log('isShowPayment: ', isShowPayment)
        showPayment.value = isShowPayment
        if (!isShowPayment) {
          return
        }
        // cards
        if (signup.meta.plan[5].children?.status) {
          paymentStatus.value = signup.meta.plan[5].children?.status
        }

        let _cards = signup.meta.plan[5].children.card
        const statusOrder = [true, false]
        if (_cards && _cards.length > 0) {
          _cards = _cards.sort((a, b) => {
            return statusOrder.indexOf(a.isDefault) - statusOrder.indexOf(b.isDefault)
          })
          cards.value = _cards
          setDefaultCard()
        } else {
          initiatePayment()
        }

        const z_id = signup.meta.plan[5].children?.z_id
        if (z_id) {
          zId.value = z_id
          planStore.zId = z_id
        }
      }
    }

    // Developer button style
    const devButtonStyle = {
      width: '240px', // Same width as apple-pay-button
      height: '40px' // Same height as apple-pay-button
    }

    const addNewCard = async () => {
      loadingPayment.value = true
      try {
        await initiatePayment()
        loadingPayment.value = false
      } finally {
        loadingPayment.value = false
      }
    }

    const setDefaultCard = () => {
      // console.log('cards', cards)
      const defaultCard = cards.value.find((card) => card.isDefault)
      if (defaultCard) {
        selectedCard.value = defaultCard.paymentId
      }
    }

    // Submit payment when user selects a card and clicks submit
    const submitCardSelection = async () => {
      if (!selectedCard.value) {
        // No card selected
        Swal.fire({
          icon: 'warning',
          title: 'No Payment Method Selected',
          text: 'Please select a payment method to proceed.'
        })
        return
      }

      loadingPayment.value = true // Start loading spinner
      try {
        // Call the store action to submit the selected payment method
        await planStore.payment_method_id(selectedCard.value, 'true')
        // Navigate to success page after payment submission
        router.push({ name: 'success-welcome-to-viewqwest' })
      } catch (error) {
        console.error('Error processing payment:', error)
        Swal.fire({
          icon: 'error',
          title: 'Payment Error',
          text: 'Something went wrong during payment processing. Please try again.'
        })
      } finally {
        loadingPayment.value = false // Stop loading spinner
      }
    }

    watch(cards, () => {
      // console.log('cards', cards)
      if (cards.value.length > 0) {
        setDefaultCard()
      }
    })

    watch(cards, () => {})

    watch(
      () => planStore.goto_welcome,
      (goto) => {
        if (goto) {
          router.push({ name: 'success-welcome-to-viewqwest' })
        }
      },
      { immediate: true }
    )

    const getCardImage = (type: string) => {
      try {
        return new URL(`../assets/cards/${type}.svg`, import.meta.url).href
      } catch (e) {
        console.error('Error loading card image:', e)
        return ''
      }
    }

    const annualPlanModal = (payment_terms) => {
      hasAnnual.value = true
      const annual = payment_terms.children
      const plan_long_description = annual[0].plan_long_description
      const plan_description = annual[0].plan_description
      const plan_banner = annual[0].plan_banner
      const planDesc = document.getElementById('annualPlanDescription')
      if (planDesc) {
        planDesc.innerHTML = plan_description
      }
      // console.log('annualPlanModal: ', annual)

      const swalCustomButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-okay',
          cancelButton: 'btn btn-no',
          container: 'payment-terms-modal-container',
          popup: 'payment-terms-modal-popup',
          header: 'payment-terms-modal-header',
          title: 'payment-terms-modal-title',
          content: 'payment-terms-modal-content',
          actions: 'payment-terms-modal-actions'
        },
        buttonsStyling: false
      })
      swalCustomButtons
        .fire({
          html: `
          <div class="discount-container">
            <div class="row-bg">
                <div class="col-custom">
                    <div class="col-image" style="
                        background-image: url(${plan_banner});">
                    </div>
                    <div class="discount-ext-wrapper">
                        <p id="discountLongDesc" class="discount-text">
                            ${plan_long_description}
                        </p>
                    </div>
                </div>
            </div>
          </div>
          `,
          padding: '0',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCancelButton: true,
          confirmButtonText: 'Yes, please!',
          cancelButtonText: 'No, thank you.'
        })
        .then((result) => {
          if (result.isConfirmed) {
            toggleBilling('yearly')
          }
        })
    }

    // Andre
    let isPaymentInitiated = false
    const initiatePayment = async () => {
      if (isPaymentInitiated) return

      isPaymentInitiated = true
      isZuoraShow.value = true
      // console.log('Initiating Zuora payment...')

      try {
        await planStore.generatePayment()
      } catch (error) {
        console.error('Error initiating Zuora payment:', error)
      } finally {
        isPaymentInitiated = false
      }
    }

    // OLD CODE
    // // Method to initiate Zuora payment
    // const initiatePayment = async () => {
    //   isZuoraShow.value = true
    //   console.log('Initiating Zuora payment...')
    //   await planStore.generatePayment()
    // }

    // Check if Zuora payment page is loaded
    const isHostedPageLoaded = ref(planStore.hostedPageLoaded)

    // Watch for changes in the loading prop
    watch(
      () => props.loading,
      (newValue) => {
        if (!newValue) {
          fetchSummaryDetails()
        }
      }
    )

    // Call fetchSummaryDetails on component mount
    onMounted(() => {
      if (import.meta.env.VITE_production === 'no') {
        isDevEnv.value = true
      }
      //const selectedRouter = planStore.getSelectedPlan.selectedRouter
      //socket.emit('itemPurchased', selectedRouter)
      fetchSummaryDetails()
    })

    return {
      billing,
      toggleBilling,
      planSpeed,
      planPrice,
      annualPriceFormatted,
      routerName,
      monthlyTotal,
      oneTimeTotal,
      addedServices,
      freebiesDetails,
      promoDetails,
      promoDetailsLabel,
      paymentDescription,
      initiatePayment,
      isHostedPageLoaded,
      callPaymentMethodId,
      isDevEnv,
      loadingPayment,
      cards,
      selectedCard,
      addNewCard,
      getCardImage,
      showPayment,
      noPaymentMethod,
      isZuoraShow,
      devButtonStyle,
      submitCardSelection,
      planCharges,
      hasAnnual
    }
  }
})
</script>

<style>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Optional backdrop */
  z-index: 9999;
}
.payment-terms-modal-actions {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Optional: adds space between the buttons */
}

.payment-terms-modal-actions .btn {
  width: 100%; /* Optional: makes buttons take the full width */
}

.discount-text {
  font-weight: bold;
}
#zuora_payment,
#z_hppm_iframe {
  height: auto !important;
  min-height: 500px !important; /* Set a sensible minimum height */
}
</style>
