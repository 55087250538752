<template>
  <header class="bg-white shadow-md">
    <div class="max-w-screen-xl mx-auto flex items-center justify-between p-4">
      <!-- Logo and Brand Name -->
      <div class="flex items-center w-1/2 md:w-auto">
        <img
          src="@/assets/logo.png"
          alt="Logo"
          width="200"
          height="40"
          class="h-8 md:h-10 object-contain"
        />
      </div>

      <div
        v-if="isMobile"
        class="md:hidden focus:outline-none grid grid-rows-[auto,auto,1fr] w-1/2 relative border-b-2 border-red-600"
      >
        <div class="flex items-center gap-2">
          <input
            v-model="promoCodeInput"
            @input="onInputChange"
            type="text"
            placeholder="Promo Code"
            class="border-0 focus:outline-none focus:ring-0 w-full bg-transparent"
          />

          <button
            v-if="!isPromoCodeValid || isLoading"
            @click="applyPromoCode"
            class="text-red-600 py-2 px-4 rounded-md transition duration-300 focus:outline-none focus:ring-2 flex items-center"
            :disabled="isLoading"
          >
            <template v-if="isLoading">
              <svg
                class="animate-spin h-5 w-5 mr-2 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span class="sr-only">Applying promo code...</span>
            </template>
            <template v-else>
              <ArrowRightCircleIcon class="h-5 w-5 ml-2 text-red-600" />
            </template>
          </button>

          <!-- Remove Button (X) to clear the promo code -->
          <button
            v-if="promoCodeInput && isPromoCodeValid"
            @click="clearPromoCode"
            class="text-gray-600 rounded-full transition duration-200 focus:outline-none w-10 h-10 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.293-10.707a1 1 0 00-1.414-1.414L10 8.586 7.121 5.707a1 1 0 00-1.414 1.414L8.586 10l-2.879 2.879a1 1 0 101.414 1.414L10 11.414l2.879 2.879a1 1 0 101.414-1.414L11.414 10l2.879-2.879z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>

        <!-- Promo Description displayed below input if promo code is valid -->
        <p
          v-if="isPromoCodeValid && description"
          class="text-red-600 text-center font-bold"
          style="font-size: 10px"
        >
          {{ description }}
        </p>
      </div>

      <!-- Navigation Links for Desktop and Mobile -->
      <nav
        :class="[isMenuOpen ? 'block' : 'hidden', 'w-full md:w-auto md:flex md:space-x-8']"
        class="mt-4 md:mt-0"
      >
        <router-link to="/" :class="linkClasses(0)" @click.prevent="goToStep(0)">
          <span
            :class="{
              'text-red-500': planStore.isPlanSelectionForm
            }"
            >1. Plan Selection</span
          >
        </router-link>
        <router-link to="/" :class="linkClasses(1)" @click.prevent="goToStep(1)">
          <span
            :class="{
              'text-red-500': planStore.isRegistrationForm
            }"
            >2. Registration</span
          >
        </router-link>
        <router-link to="/" :class="linkClasses(2)" @click.prevent="goToStep(2)">
          <span
            :class="{
              'text-red-500': planStore.isPaymentForm
            }"
            >3. Payment</span
          >
        </router-link>
      </nav>

      <!-- Promo Code Input and Button/Clear Icon for Desktop -->
      <div class="hidden md:flex items-start space-x-2">
        <div class="flex flex-col justify-center w-full">
          <input
            v-model="promoCodeInput"
            @input="onInputChange"
            type="text"
            placeholder="Enter Promo Code"
            class="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring focus:ring-red-500 focus:border-transparent transition duration-200"
          />
          <p v-if="isPromoCodeValid && description" class="text-sm text-red-600 w-full text-center">
            {{ description }}
          </p>
        </div>
        <button
          v-if="!isPromoCodeValid || isLoading"
          @click="applyPromoCode"
          class="bg-red-600 text-white py-2 px-4 h-auto rounded-md hover:bg-red-700 transition duration-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-red-500 flex items-center"
          :disabled="isLoading"
        >
          <template v-if="isLoading">
            <svg
              class="animate-spin h-5 w-5 mr-2 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span class="sr-only">Applying promo code...</span>
          </template>
          <template v-else>
            Apply
            <ArrowRightCircleIcon class="h-5 w-5 ml-2 text-white" />
          </template>
        </button>
        <button
          v-else
          @click="clearPromoCode"
          class="bg-red-600 text-white min-w-10 min-h-10 rounded-full hover:bg-red-700 transition duration-200 shadow-lg focus:outline-none focus:ring-2 focus:ring-red-400 flex items-center justify-center"
        >
          <XMarkIcon class="h-6 w-6 text-white" />
        </button>
      </div>
    </div>

    <!-- Promo Code Input and Button/Clear Icon for Mobile -->
    <div v-if="isMenuOpen" class="mt-4 flex flex-col space-y-2 items-center md:hidden px-4">
      <input
        v-model="promoCodeInput"
        type="text"
        placeholder="Enter Promo Code"
        class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-red-500 focus:border-transparent transition duration-200 w-full"
      />
      <button
        v-if="!isPromoCodeValid || isLoading"
        @click="applyPromoCode"
        class="bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition duration-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-red-500 w-full flex items-center"
        :disabled="isLoading"
      >
        <template v-if="isLoading">
          <svg
            class="animate-spin h-5 w-5 mr-2 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span class="sr-only">Applying promo code...</span>
        </template>
        <template v-else>
          Apply
          <ArrowRightCircleIcon class="h-5 w-5 ml-2 text-white" />
        </template>
      </button>
      <!-- Clear Icon for mobile -->
      <button
        v-else
        @click="clearPromoCode"
        class="bg-gray-200 text-gray-600 py-2 px-3 rounded-full hover:bg-gray-300 transition duration-200 shadow-lg focus:outline-none focus:ring-2 focus:ring-gray-400 w-full flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.293-10.707a1 1 0 00-1.414-1.414L10 8.586 7.121 5.707a1 1 0 00-1.414 1.414L8.586 10l-2.879 2.879a1 1 0 101.414 1.414L10 11.414l2.879 2.879a1 1 0 101.414-1.414L11.414 10l2.879-2.879z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { ArrowRightCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { usePlanStore } from '@/stores/planStore'

export default defineComponent({
  name: 'AppHeader',
  components: {
    ArrowRightCircleIcon,
    XMarkIcon
  },
  props: {
    steps: {
      type: Array,
      required: true
    },
    promoCode: {
      type: String
    },
    isMobile: {
      // Define isMobile here
      type: Boolean,
      required: true
    }
  },
  emits: ['applyPromoCode'], // Emit event to parent component
  setup(props, { emit }) {
    const planStore = usePlanStore()

    const isMenuOpen = ref(false)
    const description = ref(null)
    const promoCodeInput = ref(props.promoCode || '') // Create a local variable for promo code input
    const isLoading = ref(false) // Loading state for button
    const isPromoCodeValid = ref(false) // Track if the promo code is valid

    const linkClasses = (index: number) => {
      return [
        'block font-semibold transition duration-200 mt-2 md:mt-0 md:inline-block',
        props.steps[index]
          ? 'text-gray-700 hover:text-red-600 cursor-pointer'
          : 'text-gray-400 cursor-not-allowed'
      ].join(' ')
    }

    const goToStep = (index: number) => {
      if (props.steps[index]) {
        // console.log(`Navigating to step ${index + 1}`)
      }
    }

    // Watch for changes in the promoCode prop
    watch(
      () => props.promoCode, // The prop to watch
      (newPromoCode, oldPromoCode) => {
        if (newPromoCode !== oldPromoCode && newPromoCode) {
          // console.log('Promo code changed:', newPromoCode)
          promoCodeInput.value = newPromoCode // Update local input with new promo code
          applyPromoCode() // Automatically apply promo code when it changes
        }
      },
      { immediate: true } // Trigger this watcher immediately when the component is created
    )

    // Watch for changes in the description prop from planStore
    watch(
      () => planStore.description, // Watch the description from the store
      (newDescription, oldDescription) => {
        if (newDescription !== oldDescription) {
          console.log('Description changed:', newDescription)
          description.value = newDescription // Update prop with new description
        }
      },
      { immediate: true }
    )

    const onInputChange = () => {
      if (!promoCodeInput.value) {
        isPromoCodeValid.value = false // Set to invalid if input is empty
      }
    }

    const applyPromoCode = () => {
      if (!promoCodeInput.value) {
        return
      }

      isLoading.value = true // Set loading state to true
      setTimeout(() => {
        emit('applyPromoCode', promoCodeInput.value) // Emit the local promo code value to the parent
        isPromoCodeValid.value = true // Mark promo code as valid
        isLoading.value = false // Reset loading state
      }, 1000) // Simulate an API call delay
    }

    const clearPromoCode = () => {
      promoCodeInput.value = '' // Clear the promo code input
      isPromoCodeValid.value = false // Reset promo code validity
      window.location.href = import.meta.env.VITE_ORIGIN
    }

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value
    }

    return {
      promoCodeInput,
      description,
      isPromoCodeValid, // Add the validity flag
      linkClasses,
      applyPromoCode,
      clearPromoCode, // Expose the clear function
      goToStep,
      toggleMenu,
      isMenuOpen,
      isLoading,
      onInputChange,
      planStore
    }
  }
})
</script>
