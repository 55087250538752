<template>
  <AppHeader
    :steps="steps"
    :promoCode="promoCode || undefined"
    @applyPromoCode="applyPromoCode"
    :isPromoCodeValid="isPromoCodeValid"
    :isMobile="isMobile"
  />

  <div class="p-8">
    <div v-if="isLoading" class="loader-overlay">
      <Loader />
    </div>
    <div class="home text-center mt-5">
      <!-- Tab Component -->
      <TabComponent
        :activeTab="currentTab"
        @tab-selected="onTabSelected"
        :tabNames="propertyNames"
      />

      <!-- Plans container -->
      <div id="plansSection" class="plans-container mt-10 max-w-screen-xl lg:px-6 mx-auto">
        <!-- Add the styled section below the tab component using Tailwind CSS -->
        <div
          v-if="displayedPlans.plans && displayedPlans.plans.length > 0"
          class="bg-black text-white p-4 lg:p-6 m-6 border-b-4 border-red-600 text-left plans-container w-full max-w-screen-xl mx-auto"
        >
          <h1 class="text-xl font-semibold">Choose your upgraded plan.</h1>
        </div>

        <div>
          <!-- Display plans only if a tab is selected and plans are available -->
          <div v-if="displayedPlans.plans && displayedPlans.plans.length > 0" class="category">
            <!-- Mobile Carousel view -->
            <Carousel v-if="isMobile" :itemsToShow="1.1" :wrapAround="true" :transition="500">
              <Slide v-for="(plan, index) in displayedPlans.plans" :key="`carousel-slide-${index}`">
                <PlanComponent
                  :planData="plan"
                  :selectedPlan="selectedPlan"
                  @selectPlan="selectPlanHandler"
                  :isRouter="isRouter"
                  :isMobile="isMobile"
                  :activeTab="currentTab"
                />
              </Slide>

              <template #addons>
                <div>
                  <Navigation>
                    <template #prev>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="h-[500px] w-[500px] text-red-500 !important"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </template>
                    <template #next>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="h-[500px] w-[500px] text-red-500 !important"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </template>
                  </Navigation>
                </div>
                <div>
                  <Pagination />
                </div>
              </template>
            </Carousel>

            <!-- Desktop List view -->
            <div v-else>
              <div
                v-for="(plan, index) in displayedPlans.plans"
                :key="`plan-${index}`"
                class="mb-6"
              >
                <PlanComponent
                  :planData="plan"
                  :selectedPlan="selectedPlan"
                  @selectPlan="selectPlanHandler"
                  :isRouter="isRouter"
                  :isMobile="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Marketing opt-in checkbox -->
      <div
        id="showMarketingOptIn"
        v-if="showMarketingOptIn"
        class="max-w-screen-xl lg:px-6 mx-auto mt-5"
      >
        <div
          class="flex flex-col md:flex-row justify-between items-center border-t pt-5 border-gray-300"
        >
          <!-- Marketing Opt-in Checkbox -->
          <div class="flex items-center mb-4 md:mb-0">
            <input
              data-test="marketing"
              type="checkbox"
              id="marketing"
              v-model="optInMarketing"
              class="mr-2 h-4 w-4 text-red-600 border-gray-300 rounded focus:ring-red-500"
            />
            <label for="marketing" class="text-sm text-gray-600">
              Don't miss out on our new products and promotions! Select this option if you want to
              receive these updates from ViewQwest Marketing. (Optional)
            </label>
          </div>

          <!-- Next Button with Arrow Icon on the Right -->
          <button
            data-test="next-button"
            v-if="!showSingpass"
            class="bg-red-600 text-white font-bold py-2 px-4 rounded hover:bg-red-700 transition duration-300 flex items-center justify-between"
            :class="{ 'cursor-not-allowed opacity-50': !selectedPlan || isLoading }"
            @click="handleNext"
            :disabled="!selectedPlan || isLoading"
          >
            <template v-if="isLoading">
              <svg
                class="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </template>
            <template v-else> Next </template>
            <ArrowRightIcon class="ml-2 h-5 w-5 text-white" />
          </button>
        </div>
      </div>

      <!-- SingpassComponent Section -->
      <div id="singpassSection" v-if="showSingpass" class="mt-10">
        <SingpassComponent @show-manual-signup="showManualSignup" />
      </div>

      <!-- Add FormComponent below SingpassComponent -->
      <div id="formSection" v-if="showManualForm" class="mt-10">
        <FormComponent @form-submitted="onFormSubmit" />
      </div>

      <!-- Add FormComponent below SingpassComponent -->
      <div id="summarySection" v-if="formSubmitted" class="mt-10">
        <SummaryComponent :loading="loadingState" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted, nextTick, watch, toRaw } from 'vue'
import { usePlanStore } from '@/stores/planStore'
import TabComponent from '@/components/TabComponent.vue'
import PlanComponent from '@/components/PlanComponent.vue'
import SingpassComponent from '@/components/SingpassComponent.vue'
import FormComponent from '@/components/FormComponent.vue'
import AppHeader from '@/components/AppHeader.vue'
import SummaryComponent from '@/components/SummaryComponent.vue'
import Loader from '@/components/Loader.vue'
import { useRoute } from 'vue-router'
import type { Plan } from '@/stores/planStore'
import type { SignupData, UTMData } from '@/interface/interfaces'
import { saveTempDataWithExpiry } from '@/utils/Utils'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { ArrowRightIcon } from '@heroicons/vue/24/solid'
import Swal from 'sweetalert2'

export default defineComponent({
  name: 'HomeView',
  components: {
    AppHeader,
    TabComponent,
    PlanComponent,
    SingpassComponent,
    FormComponent,
    SummaryComponent,
    Loader,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ArrowRightIcon
  },
  setup() {
    const isMobile = ref(false)

    const checkIfMobile = () => {
      isMobile.value = window.innerWidth <= 768 // Adjust width threshold as needed
    }

    // Set initial mobile state
    checkIfMobile()

    // Update mobile state on resize
    window.addEventListener('resize', checkIfMobile)

    onUnmounted(() => {
      window.removeEventListener('resize', checkIfMobile)
    })

    const utm_data = ref<UTMData>({
      promo_code: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null
    })
    const promoCode = ref<string | null>(null) // Add promo code state
    const planStore = usePlanStore()

    const route = useRoute()
    const steps = ref([false, false, false])
    const currentTab = ref<string>('')
    const selectedPlan = ref<Plan | null>(null) // Track selected plan
    const showSingpass = ref(false)
    const optInMarketing = ref(false)
    const showManualForm = ref(false)
    const formSubmitted = ref(false)
    const loadingState = ref(false)
    const isPromoCodeValid = ref(false)
    const isRouter = ref(false)

    const isMyInfo = ref(false)
    const promoter = ref<string | null>(null)
    const routerKey = ref<string | null>(null)

    const selectTabFromQuery = () => {
      const queryTab = (route.query.tab as string) || '' // Read tab from query parameter
      if (queryTab) {
        currentTab.value = queryTab // Set the active tab from query
      }
    }

    watch(
      () => route.query.promoter,
      (newPromoter) => {
        if (!newPromoter) return
        // console.log('promoter: ', newPromoter)
        promoter.value = newPromoter
        planStore.promoter = newPromoter // Update store when promoter changes
      }
    )

    const propertyNames = computed(() => {
      const plans = planStore.getPlans || {} // Safeguard to prevent accessing undefined/null
      return Object.keys(plans)
    })

    // Apply Promo Code Function
    const applyPromoCode = async (inputCode: string | null = null) => {
      const activePromoCode = promoCode.value || inputCode

      // Check if promo code is present, otherwise show alert
      if (!activePromoCode) {
        alert('Please enter a promo code or use the one from the URL!')
        return
      }

      try {
        // Clear the current plans using a utility function
        clearPlans()

        // Reset the UI (set currentTab to null)
        currentTab.value = ''

        // Apply the promo code and fetch the new plans
        await planStore.fetchPromoOrDefaultPlans(activePromoCode)
        isPromoCodeValid.value = true
      } catch (error) {
        console.error('Error applying promo code:', error)
        isPromoCodeValid.value = false
      }
    }

    // Utility function to clear plans
    const clearPlans = () => {
      planStore.plans.home_wifi = []
      planStore.plans.gamer_lifestyle = []
      planStore.plans.home_network = []
    }

    // Extract the 'code' parameter from the URL
    const code = ref<string | null>(null)

    const updateSteps = (step: number) => {
      if (step < steps.value.length) {
        steps.value[step] = true // Enable the next step
      }
    }

    const scrollToSection = (sectionId: string) => {
      const section = document.getElementById(sectionId)
      if (section) {
        section.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }

    // Function to save the selected plan
    const saveSelectedPlan = async (
      planPayload: any,
      temp_data: any,
      signupId?: string | null,
      idHash?: string | null
    ) => {
      // console.log('selectedPlan: ', planStore.getSelectedPlan)
      const selectedRouter: any = planStore.getSelectedPlan.selectedRouter
      const selectedEnhancements = planStore.getSelectedPlan.selectedEnhancements

      const define: SignupData = {
        origin: window.location.href || '',
        plan_id: selectedRouter?.router_key || '',
        plan_otc_name: selectedRouter?.one_time_charge_name || '',
        plan_price: selectedRouter?.price || '',
        plan_segment: selectedRouter?.plan_segment || '',
        plan_sf: selectedRouter?.router_name || '',
        plan_contract: selectedRouter?.plan_contract || '',
        plan_type: planStore.getSelectedPlan.plan?.plan_type || '',
        plan_vas: selectedEnhancements.map((vas: any) => vas?.key),
        router: selectedRouter?.router_sf || '',
        user_device: planStore.user_device || '',
        promo_code: utm_data.value.promo_code || '',
        utm_source: utm_data.value.utm_source || '',
        utm_medium: utm_data.value.utm_medium || '',
        utm_campaign: utm_data.value.utm_campaign || '',
        utm_term: utm_data.value.utm_term || '',
        aic: ''
      }

      const data = {
        isNextBtn: planStore.isNextBtn, // Track whether the Next button has been clicked
        marketing_coms: planStore.marketing_coms, // Marketing opt-in state
        define: define, // Any additional data to pass (assuming this exists from your context)

        // Plans-related data
        plans: {
          gamer_lifestyle: planStore.plans.gamer_lifestyle,
          home_network: planStore.plans.home_network,
          home_wifi: planStore.plans.home_wifi
        },

        // Selected plan details
        selectedPlan: {
          plan: planStore.selectedPlan.plan,
          finalPrice: planStore.selectedPlan.finalPrice,
          selectedEnhancements: planStore.selectedPlan.selectedEnhancements,
          selectedRouter: planStore.selectedPlan.selectedRouter,
          selectedHardwareKey: planStore.selectedPlan.selectedHardwareKey
        },

        // Other relevant fields
        defaultTab: currentTab.value || planStore.defaultTab,
        disableTab: planStore.disableTab,
        signupId: planStore.signupId, // Signup ID
        idHash: planStore.idHash, // Signup hash ID
        promoCode: planStore.promoCode, // Applied promo code
        user_device: planStore.user_device, // User device info
        hasStaticIP: planStore.hasStaticIP, // Static IP selection status
        planUpdated: planStore.planUpdated, // Whether the plan was updated
        loading: planStore.loading, // Loading state
        vendorStatus: planStore.vendorStatus, // Vendor service statuses
        Myinfo_URL: planStore.Myinfo_URL, // MyInfo URL, if present
        promoter: promoter.value,
        // Person data if applicable
        personData: planStore.personData,

        // Postal and unit details
        postalDetails: planStore.postalDetails,
        unitDetails: planStore.unitDetails,

        // VOPS related data
        vopsDetails: planStore.vopsDetails,
        summaryDetails: planStore.summaryDetails, // Summary of the signup

        // Additional state for payment
        vops_type_default: planStore.vops_type_default,
        vops_type: planStore.vops_type,
        vopsDefaultValue: planStore.vopsDefaultValue,
        activationDateCondition: planStore.activationDateCondition,
        billingActivationDateCondition: planStore.billingActivationDateCondition,
        defaultActivationDateCondition: planStore.defaultActivationDateCondition,
        hostedPageLoaded: planStore.hostedPageLoaded, // Zuora payment hosted page state
        isPayment: planStore.isPayment, // Payment initiation state
        paymentResult: planStore.paymentResult // Payment result from Zuora
      }

      // Now save the data with an expiry using saveTempDataWithExpiry (assumed to exist)
      saveTempDataWithExpiry(data)

      if (signupId) {
        define.signup_id = signupId || undefined // Convert null to undefined
      }
      if (idHash) {
        define.signup_id_hash = idHash || undefined // Convert null to undefined
      }

      // Function to compare the new data with previous data
      const isDataSame = (newData: SignupData, prevData: SignupData | null): boolean => {
        if (!prevData) return false // No previous data, so proceed to save
        return JSON.stringify(newData) === JSON.stringify(prevData)
      }

      // Validate before submitting
      if (!isDataSame(define, planStore.previousSignupData)) {
        try {
          // Use planStore action to submit signup data
          await planStore.submitSignupData(define)

          // After successful submission, save this data as the previous data
          planStore.previousSignupData = define

          // Handle successful response if needed
          // console.log('Plan saved successfully')
        } catch (error) {
          console.error('Error saving plan:', error)
        }
      } else {
        // console.log('No changes detected, skipping submission.')
      }
    }

    // Handler for the selectPlan event
    const selectPlanHandler = async (planData: any) => {
      selectedPlan.value = planData.plan // Save the selected plan
      // Save the selected plan in the store
      planStore.selectPlan(planData)

      // console.log('selectPlanHandler: ', planData)

      // Reset the selection state for all plans and mark the selected one
      displayedPlans.value.plans.forEach((plan: Plan) => {
        if (plan.id === planData.plan.id) {
          plan.isSelected = true // Mark the current plan as selected
        } else {
          plan.isSelected = false // Reset other plans
        }
      })

      const temp_data = {
        isNextBtn: false, // Add the appropriate value for isNextBtn
        marketing_coms: optInMarketing.value, // Assuming opt-in marketing data is captured here
        selected_plan: selectedPlan.value,
        planPayload: planData.plan
      }

      // Call saveSelectedPlan
      if (!planStore.Myinfo_URL) {
        await saveSelectedPlan(planData.plan, temp_data, planStore.signupId, planStore.idHash)
      }

      if (formSubmitted.value) {
        // Save the selected plan and refetch the summary
        await saveSelectedPlan(planData.plan, temp_data, planStore.signupId, planStore.idHash)

        // After saving, refetch the summary data
        await planStore.fetchSummary()

        // Scroll to the summary section after refetching the data
        scrollToSection('summarySection')
      } else {
        // Otherwise, proceed with the usual flow
        await nextTick()
        scrollToSection('showMarketingOptIn') // Scroll to the next section
      }
    }

    // Reset the plans when a tab is selected
    const onTabSelected = async (tabName: string) => {
      currentTab.value = '' // Clear previous tab selection
      await nextTick() // Wait until DOM updates

      currentTab.value = tabName // Set new tab based on name

      // Wait for DOM updates and plans re-render before scrolling
      await nextTick()
      scrollToSection('plansSection')
    }

    // Function to show manual form and scroll to it
    const showManualSignup = async () => {
      showManualForm.value = true
      await nextTick() // Ensure the DOM updates

      updateSteps(1) // Enable the Payment step
      scrollToSection('formSection') // Scroll to the form section
    }

    const handleNext = async () => {
      // Construct temp_data
      const temp_data = {
        isNextBtn: true, // Indicate that this action is from the "Next" button
        marketing_coms: optInMarketing.value, // Capture the marketing opt-in value
        selected_plan: selectedPlan.value,
        planPayload: selectedPlan.value // Assuming selectedPlan.value contains the plan data
      }
      // console.log('selectedPlan: ', selectedPlan.value)
      planStore.marketing_coms = optInMarketing.value
      // Call saveSelectedPlan if Myinfo_URL is not set
      if (planStore.Myinfo_URL) {
        await saveSelectedPlan(selectedPlan.value, temp_data, planStore.signupId, planStore.idHash)
      }

      showSingpass.value = true
      // console.log('Proceeding to next step. Opt-in for marketing:', optInMarketing.value)
      // Wait for DOM to update after showSingpass is set to true
      await nextTick()

      // Smoothly scroll to the Singpass section
      scrollToSection('singpassSection')
    }

    // New function to handle person data retrieval
    const getPersonData = async () => {
      const uid = planStore.idHash || '' // Use the idHash from the store
      const token = import.meta.env.VITE_MYINFO_TOKEN // Get token from environment
      // console.log('code: ', code.value)
      // console.log('uid: ', uid)
      // console.log('token: ', token)
      if (code.value && uid && token) {
        try {
          await planStore.fetchPersonData(code.value, uid, token) // Call the store action
          isMyInfo.value = true
        } catch (error) {
          console.error('Error fetching person data:', error)
        }
      }
    }

    // Watch for isMyInfo becoming true to scroll to the form
    watch(isMyInfo, async (newVal) => {
      if (newVal) {
        showManualForm.value = true
        await nextTick() // Ensure the DOM updates

        updateSteps(1) // Enable the Payment step
        scrollToSection('formSection')
      }
    })

    // Function to extract query parameters and populate utm_data and code
    const extractQueryParams = async () => {
      const queryParams = route.query

      // Populate utm_data and code from the query parameters
      utm_data.value.promo_code = (queryParams.promo_code as string) || null
      utm_data.value.utm_source = (queryParams.utm_source as string) || null
      utm_data.value.utm_medium = (queryParams.utm_medium as string) || null
      utm_data.value.utm_campaign = (queryParams.utm_campaign as string) || null
      utm_data.value.utm_term = (queryParams.utm_term as string) || null

      // Get the 'code' parameter if present
      code.value = (queryParams.code as string) || null

      if (code.value) {
        // console.log('Code from URL:', code.value)
        await getPersonData()
        currentTab.value = planStore?.defaultTab ?? null
        selectedPlan.value = planStore?.selectedPlan.plan ?? null
        // console.log('selectedPlan:', planStore?.selectedPlan)
        // Perform logic based on code, like an API request
      } else {
        planStore.signupId = null
        planStore.idHash = null
        localStorage.clear()
        await planStore.fetchPromoOrDefaultPlans('')
      }

      if (utm_data.value.promo_code) {
        // console.log('Promo Code from URL:', utm_data.value.promo_code)
        // Handle promo code if needed
      }
      // Get the 'router_key' parameter from the URL query
      routerKey.value = (queryParams.router as string) || null

      findPlanByRouterKey()
    }

    const findPlanByRouterKey = () => {
      if (!routerKey.value) {
        // console.log('No router_key found in query parameters.')
        return
      }
      isRouter.value = true

      // console.log(`Searching for router with key: ${routerKey.value}`)

      // Iterate through the plans in each category
      Object.keys(planStore.plans).forEach((category) => {
        const plansInCategory = planStore.plans[category]

        // console.log(`Checking category: ${category}, number of plans: ${plansInCategory.length}`)

        plansInCategory.forEach((plan) => {
          // Log the current plan being checked
          // console.log(`Checking plan: ${plan.name} (ID: ${plan.id})`)

          // Ensure that the routers object exists and has a valid structure
          if (!plan.routers || !plan.routers.type) {
            // console.log(`Plan ${plan.name} (ID: ${plan.id}) does not have valid routers.`)
            return // Skip this plan if the structure is invalid
          }

          // Unwrap the proxies for routers in both 'mesh' and 'nmesh' categories
          const allRouters = [
            ...(toRaw(plan.routers.type?.mesh) || []),
            ...(toRaw(plan.routers.type?.nmesh) || [])
          ]

          // Log all combined routers to be searched
          // console.log(`All available routers for plan ${plan.name}:`, allRouters)

          // Now search through the routers to find the one with the matching router_key
          const foundRouter = allRouters.find((router) => {
            // console.log(`Checking router with key: ${router?.router_key}`) // Log each router key being checked
            return router?.router_key === routerKey.value
          })

          if (foundRouter) {
            // console.log(`Found matching router with key: ${routerKey.value}`, foundRouter)
            selectedPlan.value = plan // Set the plan as selected
            plan.selectedRouter = foundRouter // Set the router as selectedRouter
          } else {
            // console.log(`No matching router found in plan: ${plan.name} (ID: ${plan.id})`)
          }
        })
      })

      // If selectedPlan is still null after the loop, log that no plan was found
      if (!selectedPlan.value) {
        // console.log(`No plan found with a matching router_key: ${routerKey.value}`)
      }
    }

    onMounted(async () => {
      // socket.on('newPurchase', (data) => {
      //   const selectedRouter = planStore.getSelectedPlan.selectedRouter
      //   if (selectedRouter && selectedRouter.router_key === data.router_key) {
      //     Swal.fire({
      //       title: "We're sorry!",
      //       text: 'This plan is currently out of stock. Please select another plan from our available options. Thank you for your understanding!',
      //       icon: 'warning',
      //       confirmButtonText: 'OK'
      //     }).then((result) => {})
      //   }
      // })

      promoter.value = (route.query.promoter as string) || null
      if (promoter.value) {
        // console.log('promoter on load:', promoter.value)
      }
      extractQueryParams()

      await planStore.checkVendorStatus('MyInfo')
      await planStore.checkVendorStatus('gothere-sg')
      await planStore.fetchUserDevice()

      const urlPromoCode = route.query.promo_code as string | null
      if (urlPromoCode) {
        promoCode.value = urlPromoCode
      }
      // Initially no tab is selected
      currentTab.value = ''

      selectTabFromQuery()
    })

    watch(
      () => route.query.tab,
      (newTab) => {
        currentTab.value = (newTab as string) || '' // Update tab when the route query changes
      }
    )

    const showMarketingOptIn = computed(() => {
      return displayedPlans.value.plans.length > 0 && !isMyInfo.value
    })

    const displayedPlans = computed(() => {
      if (!currentTab.value) {
        return { categoryName: '', plans: [] } // No tab selected
      }

      const plansData = planStore.getPlans
      return {
        categoryName: currentTab.value, // The active tab category name
        plans: plansData[currentTab.value] || [] // The plans for the selected tab
      }
    })

    // Watch for changes in displayedPlans.plans and trigger the scroll after they are loaded
    watch(
      () => planStore.defaultTab, // Explicit typing
      (newDefaultTab) => {
        // console.log(`Tab changed to: ${newDefaultTab}`)
        if (newDefaultTab) {
          currentTab.value = newDefaultTab
        }
      },
      { immediate: true }
    )

    // Function to handle form submission
    const onFormSubmit = async (formData: any) => {
      // console.log('Form data received:', formData)
      formData['__target_form'] = 'signup'
      // Check if promoter exists in the URL and assign it to formData
      if (planStore.promoter) {
        formData['promoter'] = planStore.promoter
      }

      if (promoter.value) {
        formData['promoter'] = promoter.value
      }

      // Add marketing communications opt-in status to formData
      formData['marketing_coms'] = planStore.marketing_coms ? 'on' : ''

      loadingState.value = true
      await planStore.submitSignupData(formData)
      loadingState.value = false
      // Wait for the DOM to update and then scroll to the summary section
      if (planStore.formSubmitted) {
        formSubmitted.value = true
        await nextTick()
        scrollToSection('summarySection')
      }
    }

    return {
      promoCode, // Add promoCode to the returned object
      applyPromoCode, // Add applyPromoCode to the returned object
      steps,
      currentTab,
      selectedPlan,
      handleNext,
      onTabSelected,
      selectPlanHandler, // Expose handler for the selectPlan event
      displayedPlans,
      isLoading: computed(() => planStore.isLoading),
      optInMarketing,
      showMarketingOptIn,
      showSingpass,
      showManualForm,
      showManualSignup,
      formSubmitted,
      onFormSubmit,
      loadingState,
      isPromoCodeValid,
      isMyInfo,
      propertyNames,
      isRouter,
      promoter,
      isMobile
    }
  }
})
</script>

<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Optional backdrop */
  z-index: 9999;
}
</style>
