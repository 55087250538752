<template>
  <div class="w-full bg-white flex flex-col items-center">
    <div class="w-full max-w-screen-xl lg:px-8 px-4 mx-auto text-left mb-6">
      <!-- Text aligned left on large screens and centered on mobile -->
      <h1 class="text-2xl md:text-3xl font-bold mb-4 text-center md:text-left">
        Let's get started - signing up is quick and easy!
      </h1>
      <p class="text-gray-600 mb-2 text-sm md:text-base">
        All prices reflected are inclusive of 9% GST. All broadband plans & promotions are subject
        to terms & conditions.
      </p>
      <p class="text-gray-600 mb-2 text-sm md:text-base">
        For existing customers, please visit our
        <a href="https://customerportal.viewqwest.com/sg/login" class="text-red-600 underline">
          Customer Portal
        </a>
        to check out exclusive plan renewal offers.
      </p>
    </div>

    <div
      v-if="computedTabNames.length > 1"
      class="w-full max-w-screen-xl lg:px-6 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4"
    >
      <!-- Loop through dynamic tab names -->
      <div
        v-for="(tabName, index) in computedTabNames"
        :key="index"
        @click="selectTab(tabName)"
        :class="[
          'p-6 md:p-10 flex items-center justify-center text-xl md:text-2xl font-bold border border-black',
          tabClasses(tabName),
          isTabDisabled(tabName) ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
        ]"
        :disabled="isTabDisabled(tabName)"
      >
        {{ formatTabName(tabName) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { usePlanStore } from '@/stores/planStore'
import { defineComponent, ref, computed, watch } from 'vue'

export default defineComponent({
  name: 'TabComponent',
  props: {
    activeTab: {
      type: String, // Accept a String for tab name
      default: ''
    },
    tabNames: {
      type: Array as () => string[], // Accept an array of strings
      default: () => [] // Default to an empty array
    }
  },
  emits: ['tab-selected'], // Emit the tab name when selected
  setup(props, { emit }) {
    const currentTab = ref<string>(props.activeTab) // Initially set from prop
    // Access the store
    const planStore = usePlanStore()

    // Static data for tab names with display labels
    const tabNameMapping: Record<string, string> = {
      home_wifi: 'Home WiFi',
      gamer_lifestyle: 'Gamer & Lifestyle',
      home_network: 'Advanced Home Network'
    }

    // Check if the tab should be disabled (no plans available)
    const isTabDisabled = (tabName: string) => {
      const plansForTab = planStore.plans[tabName] // Get plans for this tab category
      return !plansForTab || plansForTab.length === 0
    }

    // Default tabs to be used if no tabs are passed via props
    const defaultTabs = ['home_wifi', 'gamer_lifestyle', 'home_network']

    // Computed property to use default tabs if tabNames is empty
    const computedTabNames = computed(() => {
      if (props.tabNames.length > 0) {
        // Create a copy of the props.tabNames array and sort it based on the defaultTabs order
        return [...props.tabNames].sort((a, b) => defaultTabs.indexOf(a) - defaultTabs.indexOf(b))
      }
      // Return the default tab order if no props are passed
      return defaultTabs
    })

    // Watch for changes to the activeTab prop
    watch(
      () => props.activeTab,
      (newActiveTab) => {
        currentTab.value = newActiveTab
      },
      { immediate: true }
    )

    // Select tab handler
    const selectTab = (tabName: string) => {
      // console.log('selectTab: ', tabName)
      if (!isTabDisabled(tabName)) {
        currentTab.value = tabName
        emit('tab-selected', tabName)
      }
    }

    // Add dynamic classes based on the tab name
    const tabClasses = (tabName: string) => {
      const activeClass = [
        'bg-red-600 text-white',
        'shadow-lg',
        'scale-2',
        'border border-red-500',
        'ring-2 ring-red-500'
      ].join(' ')

      const tabBackgroundImages = [
        'bg-[url("@/assets/tabs/tab1bg.webp")]',
        'bg-[url("@/assets/tabs/tab2bg.webp")]',
        'bg-[url("@/assets/tabs/tab3bg.webp")]'
      ]

      const heightClass = currentTab.value ? 'h-[92px]' : 'md:h-[320px]'

      return [
        'text-white transition-all duration-300 ease-in-out bg-cover bg-center',
        currentTab.value === tabName
          ? activeClass
          : tabBackgroundImages[computedTabNames.value.indexOf(tabName)],
        heightClass,
        currentTab.value === tabName ? '' : 'hover:scale-105 hover:shadow-lg'
      ].join(' ')
    }

    // Format tab names for display using static mapping
    const formatTabName = (tabName: string) => {
      return tabNameMapping[tabName] || tabName.replace(/_/g, ' ').toUpperCase() // Default to uppercase if not in mapping
    }

    return {
      currentTab,
      selectTab,
      tabClasses,
      formatTabName,
      computedTabNames,
      isTabDisabled
    }
  }
})
</script>
