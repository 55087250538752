<template>
  <div
    class="w-full max-w-screen-xl lg:px-8 mx-auto my-10 p-6 bg-white rounded-lg shadow-md h-full"
  >
    <input data-test="selected_plan_price" type="hidden" v-model="finalPrice" />
    <!-- Plan Overview -->
    <div class="grid grid-cols-1 md:grid-cols-4 gap-6 items-stretch h-full">
      <!-- Plan Image Container -->
      <div class="col-span-1 flex flex-col justify-center items-center">
        <img
          v-if="plan.image"
          :src="plan.image"
          alt="Plan Image"
          class="w-full h-auto mb-4 rounded-md"
        />
      </div>

      <div
        v-if="plan?.tab_name == 'bundle'"
        class="col-span-2 flex flex-col items-start md:border-l md:border-gray-300 pl-4 h-full space-y-6"
      >
        <!-- Gallery Section -->
        <div class="w-full border-b-2 border-gray-300 pb-4">
          <div class="flex justify-center overflow-x-auto">
            <div
              v-for="(g, idx) in plan.gallery"
              :key="idx"
              class="flex justify-center items-center w-[300px] bg-gray-100 border-2 border-r"
            >
              <img
                :src="g"
                alt="Gallery image"
                class="max-w-full max-h-full object-contain"
                v-if="g"
              />
              <div
                v-else
                class="text-sm text-gray-500 flex items-center justify-center w-full h-full"
              >
                No Image Available
              </div>
            </div>
          </div>
        </div>

        <!-- Content Section -->
        <div class="w-full px-4">
          <h1 class="text-[#CF202F] text-2xl md:text-4xl font-bold mb-2 text-left">
            {{ plan.default_router.name }}
          </h1>

          <div
            v-if="plan.description"
            v-html="plan.description"
            class="leading-relaxed text-gray-950 text-left"
          ></div>
        </div>
      </div>

      <!-- Hardware Section -->
      <div
        v-if="plan?.tab_name !== 'bundle'"
        class="col-span-1 flex flex-col items-start md:border-l md:border-gray-300 pl-4 h-full"
      >
        <h2 class="text-lg font-semibold mb-2 md:mb-4">Choose Hardware</h2>

        <!-- Hardware Selection Buttons -->
        <div class="flex flex-wrap space-x-2 mb-4">
          <button
            v-if="hasMeshRouters"
            :class="[
              selectedHardwareKey === 'mesh' ? 'bg-red-600 text-white' : 'bg-gray-100',
              'py-2 px-3 rounded-md text-sm md:text-base'
            ]"
            @click="selectHardware('mesh')"
          >
            Mesh
          </button>
          <button
            v-if="hasNMeshRouters"
            :class="[
              selectedHardwareKey === 'nmesh' ? 'bg-red-600 text-white' : 'bg-gray-100',
              'py-2 px-3 rounded-md text-sm md:text-base'
            ]"
            @click="selectHardware('nmesh')"
          >
            Non-Mesh
          </button>
        </div>

        <!-- Routers List -->
        <div class="mt-4 flex flex-col w-full space-y-4">
          <div v-for="(router, idx) in filteredRouters" :key="idx" class="flex items-center w-full">
            <div class="col-span-1">
              <input
                type="radio"
                :id="'router-' + idx"
                :value="router"
                v-model="selectedRouter"
                @click="enabledSubmit"
                class="form-radio min-h-4 min-w-4 custom-radio mr-4 mt-1"
              />
            </div>
            <div class="grid grid-cols-3 items-center w-full">
              <!-- TL want to remove this click label -->
              <!-- <label :for="'router-' + idx" class="font-bold text-left block">
                {{ router.name }}
              </label> -->
              <label class="font-bold text-left block col-span-2">
                {{ router.name }}
              </label>

              <!-- Responsive Information Icon with Tooltip -->
              <InformationCircleIcon
                v-if="router.router_description"
                tabindex="0"
                v-tooltip="{
                  content: tipRouter(router),
                  theme: 'info-tooltip',
                  html: true,
                  triggers: ['click']
                }"
                :class="[
                  'text-gray-500 cursor-pointer h-6 w-6'
                  // router.name.length > 24 ? 'h-8 w-8' : 'h-5 w-5'
                ]"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Plan Enhancements Section -->
      <div
        v-if="plan?.tab_name !== 'bundle'"
        class="col-span-1 flex flex-col items-start md:border-l md:border-gray-300 pl-4 h-full"
        data-test="enhance-your-plan-container"
      >
        <h2 class="text-lg font-semibold mb-2 md:mb-4" data-test="enhance-your-plan-title">
          Enhance Your Plan
        </h2>
        <div class="space-y-2 flex flex-col items-start" data-test="enhancements-list">
          <div
            v-for="(enhance, index) in enhancements"
            :key="index"
            class="flex items-center"
            :data-test="'enhancement-item-' + index"
          >
            <input
              type="checkbox"
              :id="'enhance-' + index"
              v-model="enhance.selected"
              @click="enabledSubmit"
              class="form-checkbox h-4 w-4 custom-checkbox"
              :disabled="enhance.isDefault"
              :class="{ 'disabled-vas': enhance.isDefault }"
              :data-test="'enhancement-checkbox-' + index"
            />
            <label class="ml-2 text-sm md:text-base" :data-test="'enhancement-label-' + index">
              {{ enhance.name }}
            </label>
            <InformationCircleIcon
              v-if="enhance.description"
              tabindex="0"
              v-tooltip="{
                content: tipVas(enhance),
                theme: 'info-tooltip',
                html: true,
                triggers: ['click']
              }"
              class="text-gray-500 cursor-pointer h-5 w-5 ml-2"
              :data-test="'enhancement-info-icon-' + index"
            />
          </div>
        </div>
      </div>

      <!-- Plan Summary Section -->
      <div
        class="col-span-1 flex flex-col justify-center items-center md:border-l md:border-gray-300 p-4 h-full"
      >
        <h2 class="text-lg md:text-xl font-bold text-center mb-2 text-black">Your Plan</h2>

        <p class="text-3xl md:text-4xl font-bold text-red-600 mb-1 inline-flex items-baseline">
          ${{ finalPrice }}<span class="text-black text-xl md:text-2xl font-bold ml-1">/mth</span>
        </p>
        <div v-if="usualPrice" class="font-bold text-black line-through mb-1 text-center">
          <span class="plan-uprice-text font-bold">U.P. ${{ usualPrice }}/mth</span>
        </div>
        <div
          v-if="defaultDescription"
          v-html="defaultDescription"
          class="text-sm text-gray-900 text-center mb-4 border-b-2 pb-4"
        ></div>

        <button
          data-test="select-button"
          class="px-6 py-2 md:px-8 border rounded-md transition duration-300 ease-in-out"
          :class="{
            'bg-white border-black text-black hover:bg-gray-100':
              enabledSubmitButton || !isSelectedPlan,
            'bg-gray-300 border-gray-400 text-gray-600 cursor-not-allowed':
              !enabledSubmitButton && isSelectedPlan
          }"
          @click="selectPlan"
          :disabled="!enabledSubmitButton && isSelectedPlan"
        >
          {{ !enabledSubmitButton && isSelectedPlan ? 'SELECTED' : 'SELECT' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from 'vue'
import { usePlanStore } from '@/stores/planStore'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { loadTempDataWithExpiry } from '@/utils/Utils'

interface RouterOption {
  key: string
  name: string
  price: string
  cf_price?: string
  type: string
}

interface Enhancement {
  name: string
  key: string
  selected: boolean
  price: number
  description?: string
  isDefault: boolean
}

export default defineComponent({
  name: 'PlanComponent',
  components: {
    InformationCircleIcon // Register the icon
  },
  props: {
    planData: {
      type: Object as () => any,
      required: true
    },
    selectedPlan: {
      type: Object as () => any,
      required: false
    },
    isRouter: {
      type: Boolean,
      required: false
    },
    isMobile: {
      // Define isMobile here
      type: Boolean,
      required: true
    },
    activeTab: {
      type: String, // Accept a String for tab name
      default: ''
    }
  },
  setup(props, { emit }) {
    const storedTempData = loadTempDataWithExpiry()
    const plan = props.planData

    const planStore = usePlanStore()

    const hasMeshRouters = computed(
      () => plan.routers.type.mesh && plan.routers.type.mesh.length > 0
    )
    const hasNMeshRouters = computed(
      () => plan.routers.type.nmesh && plan.routers.type.nmesh.length > 0
    )

    const routerOptions = ref<RouterOption[]>([])

    const allRouters: RouterOption[] = []

    const tab_name = ref<string | null>(props.activeTab)

    if (hasMeshRouters.value) {
      allRouters.push(...plan.routers.type.mesh)
    }

    if (hasNMeshRouters.value) {
      allRouters.push(...plan.routers.type.nmesh)
    }

    routerOptions.value = [...new Set(allRouters)]

    const selectedHardwareKey = ref<string | null>(null)
    const selectedRouter = ref<RouterOption | null>(null)
    const usualPrice = ref<number>(0)
    const defaultDescription = ref<string | null>(null)
    const enabledSubmitButton = ref<boolean>(true)

    const enhancements = ref<Enhancement[]>(
      plan.vas.map((vas: any) => ({
        name: vas.name,
        key: vas.key,
        description: vas.description,
        selected: plan.default_vas.some((defaultVas: any) => defaultVas.key === vas.key),
        price: parseFloat(vas.price),
        isDefault: plan.default_vas.some((defaultVas: any) => defaultVas.key === vas.key)
      }))
    )

    if (plan.default_router && plan.default_router.usual_price) {
      usualPrice.value = parseFloat(plan.default_router.usual_price)
    }

    onMounted(() => {
      // Make it false so disable the button if theres selected plan this work only if using my info
      if (storedTempData?.selectedPlan) {
        enabledSubmitButton.value = false
      }

      // This condition is to retain the enhancement if has been selected
      if (
        Array.isArray(storedTempData?.selectedPlan?.selectedEnhancements) &&
        storedTempData.selectedPlan.selectedEnhancements.length > 0
      ) {
        // console.log('SELECTED ENHANCEMENTS UPDATED:', enhancements.value)

        // WORKING ANDRE
        // if (isSelectedPlan.value) {
        //   enhancements.value.splice(
        //     0,
        //     enhancements.value.length,
        //     ...planStore.getSelectedPlan.selectedEnhancements
        //   )
        // }

        // console.log('Before plan isSelectedPlan.value', plan)

        if (isSelectedPlan.value) {
          // Get selected enhancements
          const selectedEnhancements = planStore.getSelectedPlan.selectedEnhancements

          // Extract keys of the selected enhancements for comparison
          const selectedKeys = selectedEnhancements.map((enh) => enh.key)

          // Filter not selected enhancements from storedTempData
          const notSelectedEnhancements = storedTempData?.selectedPlan?.plan?.vas.filter(
            (enhancement: any) => !selectedKeys.includes(enhancement.key)
          )

          // Merge selected and not selected enhancements
          const mergedEnhancements = [...selectedEnhancements, ...notSelectedEnhancements]

          // Deduplicate and preserve order from `storedTempData.selectedPlan.plan.vas`
          const uniqueEnhancements = storedTempData.selectedPlan.plan.vas.filter(
            (enhancement: any) => mergedEnhancements.some((e) => e.key === enhancement.key)
          )

          // Update enhancements.value while keeping the original layout
          enhancements.value.splice(0, enhancements.value.length, ...uniqueEnhancements)
        }

        // console.log('AFTER plan isSelectedPlan.value', plan)
      } else {
        selectedRouter.value =
          planStore.selectedPlan?.selectedRouter || storedTempData?.selectedPlan?.selectedRouter
        selectedHardwareKey.value =
          planStore.selectedPlan?.selectedHardwareKey ||
          storedTempData?.selectedPlan?.selectedHardwareKey
      }

      // console.log('onMounted:', tab_name.value, planStore.defaultTab)
      if (tab_name.value == '') {
        tab_name.value = planStore.defaultTab
      }

      if (plan.default_router) {
        const defaultRouterType = plan.default_router.type.toLowerCase()
        selectedHardwareKey.value = defaultRouterType === 'nmesh' ? 'nmesh' : 'mesh'
      } else {
        selectedHardwareKey.value = 'mesh'
      }

      const newEnhancements = planStore.selectedPlan.selectedEnhancements
      // console.log('newEnhancements: ', newEnhancements, '--', isSelectedPlan.value)
      if (newEnhancements && newEnhancements.length > 0 && isSelectedPlan.value) {
        // console.log('onMounted: Initial selectedEnhancements', newEnhancements)
        enhancements.value.forEach((enhance) => {
          enhance.selected = newEnhancements.some(
            (selectedEnhance) => selectedEnhance.key === enhance.key
          )
        })

        // console.log('selectedRouter: ', planStore.selectedPlan?.selectedRouter)
        selectedRouter.value =
          planStore.selectedPlan?.selectedRouter || storedTempData?.selectedPlan?.selectedRouter

        selectedHardwareKey.value = planStore.selectedPlan?.selectedHardwareKey
        enabledSubmitButton.value = false
      }
    })

    const filteredRouters = computed<RouterOption[]>(() => {
      return routerOptions.value.filter(
        (router) => router.type.toLowerCase() === selectedHardwareKey.value
      )
    })

    watch(
      () => filteredRouters.value,
      (newRouters) => {
        if (!newRouters.includes(selectedRouter.value!)) {
          selectedRouter.value = newRouters[0] || null
          enabledSubmitButton.value = true
        }
        // console.log('updatePriceAndDescription')
        updatePriceAndDescription()
      }
    )

    watch(selectedRouter, (newRouter, oldRouter) => {
      // console.log('Selected router changed')
      // console.log('Old router:', oldRouter)
      // console.log('New router:', newRouter)
      // Perform any additional logic you want when the selectedRouter changes
      updatePriceAndDescription()
    })

    const enabledSubmit = () => {
      enabledSubmitButton.value = true
    }

    const tipRouter = (router: RouterOption) => router.router_description
    const tipVas = (enhance: Enhancement) => enhance.description

    const finalPrice = computed(() => {
      let basePrice = 0
      let currentRouter = null

      if (selectedRouter.value) {
        currentRouter = selectedRouter.value
        basePrice = parseFloat(currentRouter.price ?? currentRouter.cf_price ?? '0')
      }

      // console.log('PRICE funciton', enhancements.value)

      // WORKING ANDRE
      // const enhancementsPrice = enhancements.value.reduce((sum, enhance) => {
      //   if (enhance.selected) {
      //     sum += enhance.price
      //   }
      //   return sum
      // }, 0)

      // ANDRE
      const enhancementsPrice = enhancements.value.reduce((sum: any, enhance: any) => {
        if (enhance.selected) {
          // Ensure price is converted to a number, in case it's a string
          sum += parseFloat(enhance?.price) || 0
        }
        return sum
      }, 0)

      let oneVoicePrice = 0
      if (
        plan.default_vas.some(
          (vas: any) => vas.key.includes('ov_premium') || vas.key.includes('ov_business')
        )
      ) {
        const oneVoiceVAS = plan.default_vas.find(
          (vas: any) => vas.key.includes('ov_premium') || vas.key.includes('ov_business')
        )
        oneVoicePrice = parseFloat(oneVoiceVAS?.price ?? '0')
      }

      const totalPrice = basePrice + enhancementsPrice + oneVoicePrice

      return totalPrice.toFixed(2)
    })

    const updatePriceAndDescription = () => {
      let currentRouter = null

      // Find the selected router
      for (var i = 0; i < filteredRouters.value.length; i++) {
        if (filteredRouters.value[i].name === selectedRouter.value?.name) {
          currentRouter = filteredRouters.value[i]
          break
        }
      }
      console.log('updatePriceAndDescription: ', filteredRouters.value)
      if (!currentRouter) return

      // Set the default description
      defaultDescription.value =
        plan.isChild && currentRouter.child?.short_description
          ? currentRouter.child.short_description
          : currentRouter.short_description

      // Get the usual price (legacy approach)
      let up =
        plan.isChild && currentRouter.child?.usual_price
          ? currentRouter.child.usual_price
          : currentRouter.usual_price

      usualPrice.value = Number(up).toFixed(2)

      // If the usual price equals the current price, reset usual price to 0
      if (Number(usualPrice.value) === Number(currentRouter.price)) {
        usualPrice.value = 0
      }
    }

    const selectHardware = (key: string) => {
      selectedHardwareKey.value = key
    }

    const isSelectedPlan = computed(() => {
      return planStore.selectedPlan?.plan && planStore.selectedPlan?.plan.id === plan.id
    })

    watch(
      enhancements,
      (newEnhancements, oldEnhancements) => {
        // console.log('Enhancements changed:')
        // console.log('Old:', oldEnhancements)
        // console.log('New:', newEnhancements)
      },
      { deep: true } // Deep watch to detect changes within each object in the array
    )

    const selectPlan = () => {
      const selectedEnhancements = enhancements.value.filter((enhance) => enhance.selected)
      const selectedPlanData = {
        plan: plan,
        selectedRouter: selectedRouter.value,
        selectedEnhancements: selectedEnhancements,
        finalPrice: finalPrice.value,
        selectedHardwareKey: selectedHardwareKey
      }
      emit('selectPlan', selectedPlanData)
      enabledSubmitButton.value = false
    }

    return {
      storedTempData,
      plan,
      hasMeshRouters,
      hasNMeshRouters,
      selectedHardwareKey,
      filteredRouters,
      selectedRouter,
      enhancements,
      finalPrice,
      selectHardware,
      isSelectedPlan,
      selectPlan,
      tipRouter,
      tipVas,
      defaultDescription,
      usualPrice,
      enabledSubmitButton,
      enabledSubmit,
      tab_name
    }
  }
})
</script>

<style>
.custom-radio,
.custom-checkbox {
  accent-color: gray;
}

input[type='radio'].custom-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #6b7280;
  border-radius: 50%;
  outline: none;
  transition: background 0.2s ease-in-out;
}

input[type='radio'].custom-radio:checked {
  background-color: #e3342f;
  border-color: #e3342f;
}

input[type='checkbox'].custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #6b7280;
  border-radius: 0.25rem;
  outline: none;
  transition: background 0.2s ease-in-out;
  position: relative;
}

input[type='checkbox'].custom-checkbox:checked {
  background-color: #e3342f;
  border-color: #e3342f;
}

input[type='checkbox'].custom-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 0.35rem;
  height: 0.75rem;
  border: solid white;
  border-width: 0 0.125rem 0.125rem 0;
}

.disabled-vas {
  opacity: 0.6;
  pointer-events: none;
}
.v-popper--theme-info-tooltip .v-popper__inner {
  background: #fff;
  color: black;
  padding: 24px;
  border-radius: 6px;
  border: 1px solid black;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 264px;
}

.v-popper--theme-info-tooltip .v-popper__inner ul {
  padding-left: 0;
}
.plan-widget-uprice {
  font-weight: bold;
  font-size: 1rem;
  color: #3b4a56;
}

.plan-widget-uprice small {
  font-size: 0.9rem;
  color: #3b4a56;
}

.plan-widget-uprice .plan-uprice-text {
  font-size: 20px;
  text-decoration: line-through;
  font-weight: bold;
}

.line-through {
  margin: 0 2px;
}

.plan-widget-contract__promo {
  padding: 10px;
  border-radius: 5px;
}

.plan-widget-contract__promo span {
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
}
@media (max-width: 768px) {
  /* Adjust layout for mobile */
  .grid-cols-1 {
    grid-template-columns: 1fr;
  }
  .pl-4 {
    padding-left: 1rem;
  }
  .rounded-md {
    border-radius: 0.5rem;
  }
}

ol,
ul,
menu {
  list-style: disc;
  margin: 0;
  padding: 0;
}
.v-popper__inner {
  padding: 16px;
}

.v-popper__inner p {
  margin-bottom: 12px; /* Adjusts spacing between paragraphs */
  font-size: 16px; /* Adjusts the font size */
  line-height: 1.5; /* Sets line height for better readability */
}

.v-popper__inner p b,
.v-popper__inner p i {
  font-weight: bold; /* Ensures bold text styling */
}

.v-popper__inner p i {
  font-style: italic; /* Ensures italic text styling */
}

.v-popper__inner p span {
  color: #3d5eaa;
  font-weight: bold; /* Ensure bold for the span text */
}
</style>
