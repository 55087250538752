import axios, { type AxiosInstance, type AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import Swal from 'sweetalert2'
import { usePlanStore } from '@/stores/planStore'

// Define response types for API endpoints (adjust based on actual response structures)
interface Plan {
  id: string
  name: string
  price: number
  description?: string
  plan_type: string
  // Add more fields based on your data structure
}

// Updated PlanResponse to be more dynamic
export interface PlanResponse {
  data: {
    bundle_count: number
    plan_count: number
    plans: Record<string, Plan[]> // Dynamic plans for various categories
  }
}

// Updated PromoPlanResponse to handle dynamic categories
export interface PromoPlanResponse {
  data: {
    default_tab: string // Name of the default tab (category)
    disable_tab: boolean // Indicates if the tab selection is disabled
    plans: Record<string, Plan[]> // Dynamic plans based on promo or default
  }
}

// Updated PromoResponse to handle dynamic plans and voucher description
export interface PromoResponse {
  plans: Record<string, Plan[]> // Dynamic plans fetched from promo code
  voucher: {
    voucher_description: string // Description of the applied voucher
  }
}

interface VendorStatusResponse {
  status: boolean
  msg: string
}

export interface SummaryResponse {
  data: {
    code: number
    message: string
    description: string
    data: {
      signup: {
        user_device: string
        signup_origin: string
        signup_scope: string
        signup_status: string
        id: number
        id_hash: string
        meta: {
          plan: {
            label: string
            children: Array<{
              label: string
              amount: string
              children?: Array<{
                label: string
                amount?: string
              }>
            }>
          }[]
          // Other fields based on your response structure
        }
      }
    }
  }
}

interface SignupData {
  origin: string
  plan_id: string
  signup_id?: string
  signup_id_hash?: string
  temp_data?: string
  // Add other fields as necessary
}

interface MyInfoResponse {
  Myinfo_URL: string
}

interface DeviceResponse {
  data: {
    device: string
  }
}

interface ZuoraSignatureResponse {
  signature: string
  token: string
  key: string
  tenantId: string
}

export interface SavePaymentData {
  signup_id: string
  cc_payment: string
  payment_method_id: string
  creditCardAddress1: string
  creditCardCountry: string
  creditCardCity: string
  creditCardState: string
  creditCardPostalCode: string
  email: string
  phone: string
  __target_form: string
  autoPay: string
  signup_id_hash: string
}

// Axios client instance
const apiClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL, // Replace with the actual environment variable
  timeout: 30000
})

axiosRetry(apiClient, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    const planStore = usePlanStore() // Get the planStore instance

    // console.log('planStore.isTimeOutPostalCode AXIOS:', planStore.isTimeOutPostalCode)

    // Avoid retrying if a timeout has already been flagged
    if (planStore.isTimeOutPostalCode) {
      // console.log('Skipping retry because isTimeOutPostalCode is true')
      return false // Prevent retrying
    }

    // Check for network or timeout errors
    const isTimeout = error?.code === 'ECONNABORTED' || error?.message.includes('timeout')
    if (isTimeout) {
      Swal.fire({
        title: 'Something went wrong!',
        text: 'Please try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          // Redirect only after the user clicks "OK"
          window.location.href = import.meta.env.VITE_ORIGIN
        }
      })
      return axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.response?.status === 500
    }
  }
})

// API services
export default {
  // Fetch available plans (Note: PlanResponse includes various plan categories)
  fetchPlans(): Promise<AxiosResponse<PlanResponse>> {
    return apiClient.get<PlanResponse>('plans')
  },

  applyPromo(
    signupId: string,
    promoCode: string,
    signupIdHash: string
  ): Promise<AxiosResponse<PromoResponse>> {
    return apiClient.get<PromoResponse>(
      `signups/apply/${signupId}/voucher/${promoCode}?__target_form=promocode&signup_id_hash=${signupIdHash}`
    )
  },

  fetchPlansBySignup(signupId: string): Promise<AxiosResponse<PromoPlanResponse>> {
    return apiClient.get<PromoPlanResponse>(`plans/of/signup/${signupId}`)
  },

  // Fetch vendor status for services (MyInfo, Gothere-sg)
  checkVendorStatus(vendorName: string): Promise<AxiosResponse<VendorStatusResponse>> {
    return apiClient.get<VendorStatusResponse>(
      `utilities/get-vendor-status?vendor_name=${vendorName}`
    )
  },

  // Fetch order summary after form submission
  fetchSummary(signupId: string, idHash: string): Promise<AxiosResponse<SummaryResponse>> {
    return apiClient.get<SummaryResponse>(
      `signups/fetch/${signupId}/preview?signup_id_hash=${idHash}`
    )
  },
  fetchUser(signupId: string, idHash: string): Promise<AxiosResponse<any>> {
    return apiClient.get<any>(`signups/fetch/${signupId}?signup_id_hash=${idHash}`)
  },

  // Submit signup data
  postSignupData(data: SignupData): Promise<AxiosResponse<any>> {
    return apiClient.post<any>('signups/define', data)
  },

  // Fetch user device details
  fetchUserDevice(): Promise<AxiosResponse<DeviceResponse>> {
    return apiClient.get<DeviceResponse>('utilities/eu-device')
  },

  // Get postal code details
  // fetchPostal(postalCode: string): Promise<AxiosResponse<any>> {
  //   return apiClient.get<any>(`utilities/postal/${postalCode}`)
  // },

  // Get postal code details
  fetchPostal(postalCode: string): Promise<AxiosResponse<any>> {
    const planStore = usePlanStore() // Get the planStore instance

    try {
      return apiClient.get<any>(`utilities/postal/${postalCode}`, {
        params: {
          signup_id: planStore.signupId // Replace 'yourSignupId' with the actual value
        },
        timeout: 15000 // Override timeout to 15 seconds for this request
      })
    } catch (error: any) {
      // Handle timeout error specifically
      if (error?.code === 'ECONNABORTED') {
        planStore.isTimeOutPostalCode = true
        // console.log('planStore.isTimeOutPostalCode fetchPostal', planStore.isTimeOutPostalCode)
        return Promise.reject(new Error('Request timed out after 15 seconds.'))
      }
      // Handle other errors
      return Promise.reject(error)
    }
  },

  // Get unit number details
  async fetchUnitDetails(postalCode: string, unitNumber: string): Promise<AxiosResponse<any>> {
    const planStore = usePlanStore() // Get the planStore instance

    try {
      return await apiClient.get<any>(`utilities/postal/${postalCode}/${unitNumber}`, {
        params: {
          signup_id: planStore.signupId // Replace 'yourSignupId' with the actual value
        },
        timeout: 15000 // Override timeout to 15 seconds for this request
      })
    } catch (error: any) {
      // Handle timeout error specifically
      if (error?.code === 'ECONNABORTED') {
        planStore.isTimeOutPostalCode = true
        // console.log('planStore.isTimeOutPostalCode fetchUnitDetails', planStore.isTimeOutPostalCode)
        return Promise.reject(new Error('Request timed out after 15 seconds.'))
      }
      // return await Promise.reject(error)
    }
  },

  // Push signup data to vendors
  pushToVendors(signupId: string, idHash: string): Promise<AxiosResponse<any>> {
    return apiClient.get<any>(`vendors/push/${signupId}/to/ALL?signup_id_hash=${idHash}`)
  },

  updatePrevISPForm(prevISPData: any) {
    return apiClient.post('/signups/define', prevISPData)
  },

  // Get Zuora Signature
  getZuoraSignature(data: {
    env: string
    hosted_page: string
    signup_id: string
  }): Promise<AxiosResponse<ZuoraSignatureResponse>> {
    const generateHostedPageUrl = import.meta.env.VITE_GENERATE_HOSTEDPAGE
    return apiClient.post<ZuoraSignatureResponse>(generateHostedPageUrl, data)
  },

  // Save Payment Details after Zuora Payment is completed
  savePayment(data: any): Promise<AxiosResponse<any>> {
    return apiClient.post<any>('vendors/account/payment', data)
  },

  // Fetch MyInfo data
  fetchMyInfoData(
    uid: string,
    signupId: string,
    token: string
  ): Promise<AxiosResponse<MyInfoResponse>> {
    return apiClient.post<MyInfoResponse>(import.meta.env.VITE_MYINFO_API_BASE_URL, {
      uid,
      signup_id: signupId,
      token
    })
  },

  // New method to fetch person data from the MyInfo API
  fetchPersonDataByCode(code: string, uid: string, token: string): Promise<AxiosResponse<any>> {
    return apiClient.post(import.meta.env.VITE_getPersonDataUpdate, {
      code,
      uid,
      token
    })
  },

  // Validate Apple Pay merchant session
  validateApplePaySession(signupId: number, apple_url: string) {
    return apiClient.post('vendors/applepay/validate_session', {
      signup_id: signupId,
      apple_url
    })
  },

  // Process Apple Pay payment token
  processApplePayPayment(signupId: number, paymentData: any, payment_method: string) {
    return apiClient.post('vendors/checkout/process_payment', {
      signup_id: signupId,
      payment_method: payment_method,
      paymentData
    })
  },

  // Push data layer get signup
  fetchSignupDataLayer(signupId: number, idHash: string) {
    const url = `signups/fetch/${signupId}?signup_id_hash=${idHash}`
    return apiClient.get(url)
  }
}
